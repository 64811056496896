import React from 'react'
import {RiProductHuntLine} from "react-icons/ri"
import { Link } from 'react-router-dom'
import { ShowOnLogin, ShowOnLogout } from '../../components/protect/HiddenLink'
import "./Home.scss"

const Home = () => {
  return (
    <div className='home'>
         
         <nav className='container --flex-between'>
            <div className='logo'>
                <RiProductHuntLine size={35}/>
            </div>
          <ul className='home-links'>
            <ShowOnLogout>

            <li>
                <Link to="/register">Register</Link>
                </li>
            </ShowOnLogout>
            <ShowOnLogout>
            <li>
                <button className='--btn --btn-primary'>
                <Link to="/login">Login</Link>
                </button>
                </li>
                </ShowOnLogout>
                <ShowOnLogin>
            <li>
                <button className='--btn --btn-primary'>
                <Link to="/dashboard">Dashboard</Link>
                </button>
                </li>        
                </ShowOnLogin>     
          </ul>
         </nav>
         {/*Hero Section */}

         <section className='container-hero'>
           <div className='hero-text'>
            <h2>Inventory {"&"} Stock Management</h2>
            <p>Inventory system to control and manage products in the warehouse in real time.</p>
            <div className='hero-buttons'>
            <button className='--btn --btn-secondary'>
                <Link to="/dashboard">Free Trial 1 Month</Link>
                </button>

            </div>
            <div className='--flex-start'>
              <NumberText num="14K" text="Brand owners"/>
              <NumberText num="23K" text="Active Users"/>
              <NumberText num="500+" text="Partners"/>
            </div>
           </div>
         </section>
    </div>
  )
}
const NumberText =({num,text})=>{
  return (
    <div className='--mr'>
      <h3 className='--color-white'>{num}</h3>
      <p className='--color-white'>{text}</p>
    </div>
  )
};
export default Home