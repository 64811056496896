import React, { useState } from 'react'
import styles from "./auth.module.scss"
import {GrLogin} from "react-icons/gr"
import Card from '../../components/card/Card'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { loginUser, validateEmail } from '../../services/authService'
import { SET_LOGIN, SET_NAME, SET_USER_ROLE } from '../../redux/features/auth/authSlice'
import Loader from '../../components/loader/Loader'
// import { useForm } from 'react-hook-form';


const initialState = {
  name: "",
  email: "",
  password: "",
  password2: "",
};

const Login = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setformData] = useState(initialState);
  const { email, password } = formData;

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const login=async(e)=>{
    e.preventDefault()
    console.log(formData);

    if ( !email || !password) {
      return toast.error("All fields are required");
    }
    if (!validateEmail(email)) {
        return toast.error("Please enter a valid email");
    }

    const userData = {
      email,
      password,
      
    };
   setIsLoading(true)
   try{
    const data = await loginUser(userData);
    console.log(data);
    await dispatch(SET_LOGIN(true));
    await dispatch(SET_USER_ROLE(data.role))
    await dispatch(SET_NAME(data.name));
    navigate("/dashboard");
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
   }

  }

  return (
    <div className={`container ${styles.auth}`}>
      {isLoading && <Loader/>}
        <Card>
            <div className={styles.form}>
              <div className='--flex-center'>
                <GrLogin size={35} color="#999"/>
              </div>
              <h2>Login</h2>

              <form onSubmit={login}>
                
            <input
              type="email"
              placeholder="Email"
              required
              name="email"
              value={email}
              onChange={handleInputChange}
            />
            {/* {error.email && <p className='text-red 500'{...error.email.message}></p>} */}
            <input
              type="password"
              placeholder="Password"
              required
              name="password"
              value={password}
              onChange={handleInputChange}
            />
            <span className={styles.toast}>
              
            </span>
            <button type="submit" className="--btn --btn-primary --btn-block">
              Login
            </button>
          </form>

 
          <Link to="/forgot">Forgot Password</Link>

          <span className={styles.register}>
            <Link to="/">Home</Link>
            <p> &nbsp; Don't have an account? &nbsp;</p>
            {/* <Link to="/register">Register</Link> */}
            <span>ReachUs Out</span>
          </span>


            </div>
        </Card>
    </div>
  )
}

export default Login