import { BrowserRouter,Routes,Route } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import axios from "axios";
import Forgot from "./pages/auth/Forgot";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Reset from "./pages/auth/Reset";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/dashboard/Dashboard";
import Home from "./pages/Home/Home";
import Layout from "./components/layout/Layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn, SET_LOGIN } from "./redux/features/auth/authSlice";
import { getLoginStatus } from "./services/authService";
import AddProduct from "./pages/addProduct/AddProduct";
import SaleProduct from "./pages/saleProduct/SaleProduct";
import EditProduct from "./pages/editProduct/EditProduct";
import ProductDetail from "./components/product/productDetail/productDetail";
import Profile from "./pages/profile/Profile";
import EditProfile from "./pages/profile/EditProfile";
import Contact from "./pages/contact/Contact";
import OutOfStock from "./pages/editProduct/OutOfStock";
import { getOutOfStockProducts, getProducts } from "./redux/features/product/productSlice";


axios.defaults.withCredentials = true;

function App() {

  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { isError, message } = useSelector(
    (state) => state.product
  );
  //to get if user is actually logged in or not (afterreloading).
  useEffect(() => {
    async function loginStatus() {
      const status = await getLoginStatus();
      dispatch(SET_LOGIN(status));
    }
    loginStatus();
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(getProducts());
      dispatch(getOutOfStockProducts())
    }

    if (isError) {
      console.log(message);
    }
  }, [isLoggedIn, isError, message, dispatch]);

  return (
    <BrowserRouter>
        <ToastContainer/>
       <Routes>
         <Route path="/" element={<Home/>} />
         <Route path="/login" element={<Login/>} />
         <Route path="/register" element={<Register/>} />
         <Route path="/forgot" element={<Forgot/>} />
         <Route path="/resetpassword/:resetToken" element={<Reset/>} />
         <Route path="/out-of-stock" element={
         <Sidebar>
          <Layout>
           <OutOfStock/>
          </Layout>
         </Sidebar>
         } />
         <Route path="/dashboard" element={
           <Sidebar>
          <Layout>
            <Dashboard/>
          </Layout>
          
         </Sidebar>
        } />

<Route path="/add-product" element={
         <Sidebar>
          <Layout>
            <AddProduct/>
          </Layout>
          
         </Sidebar>
        } />
   <Route
          path="/product-detail/:id"
          element={
            <Sidebar>
              <Layout>
                <ProductDetail />
              </Layout>
            </Sidebar>
          }
        >
    </Route>
        <Route
          path="/edit-product/:id"
          element={
            <Sidebar>
              <Layout>
                <EditProduct />
              </Layout>
            </Sidebar>
          }
        />

<Route
          path="/profile"
          element={
            <Sidebar>
              <Layout>
                <Profile />
              </Layout>
            </Sidebar>
          }
        />
       <Route
          path="/edit-profile"
          element={
            <Sidebar>
              <Layout>
                <EditProfile />
              </Layout>
            </Sidebar>
          }
        />
<Route path="/sell-product" 
element={
         <Sidebar>
          <Layout>
            <SaleProduct/>
          </Layout>
         </Sidebar>
        } />
            <Route
          path="/contact-us"
          element={
            <Sidebar>
              <Layout>
                <Contact />
              </Layout>
            </Sidebar>
          }
        />
       </Routes>
   
    </BrowserRouter>
  );
}

export default App;
