import React, { useEffect, useState } from "react";
import { SpinnerImg } from "../../loader/Loader";
import "./productList.scss";
import { FaEdit, FaTrashAlt,FaMinusCircle } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import Search from "../../search/Search";
import { useDispatch, useSelector } from "react-redux";
import {FILTER_PRODUCTS,selectFilteredPoducts} from "../../../redux/features/product/filterSlice";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {deleteProduct,getProducts,sellSingleProduct} from "../../../redux/features/product/productSlice";
import { Link } from "react-router-dom";
import { selectUserRole } from "../../../redux/features/auth/authSlice";


const ProductList = ({ products, isLoading }) => {
  const [search, setSearch] = useState("");
  const filteredProducts = useSelector(selectFilteredPoducts);

  const UserRole=useSelector(selectUserRole);



  const dispatch = useDispatch();

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };

  const delProduct = async (id) => {
    // console.log(id);
    await dispatch(deleteProduct(id));
    await dispatch(getProducts());
  };
  
  const sellProduct=async(id)=>{
    console.log(id);
    await dispatch(sellSingleProduct(id));
    await dispatch(getProducts())
  }

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Delete Product",
      message: "Are you sure you want to delete this product.",
      buttons: [
        {
          label: "Delete",
          onClick: () => delProduct(id),
        },
        {
          label: "Cancel",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

//sell 
const confirmSell = (id) => {
  
 console.log("inside alert");
  confirmAlert({
    title: "Sell Product",
    message: "Are you sure you want to sell this product.",
    buttons: [
      {
        label: "yes",
        onClick: () => sellProduct(id),
      },
      {
        label: "Cancel",
        // onClick: () => alert('Click No')
      },
    ],
  });
};




  //   Begin Pagination
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(filteredProducts.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredProducts.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredProducts]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredProducts.length;
    setItemOffset(newOffset);
  };
  //   End Pagination



  useEffect(() => {
    dispatch(FILTER_PRODUCTS({ products, search }));
  }, [products, search, dispatch]);

  // const isSellDisabled = product.quantity <= 0;
  return (
    <div className="product-list">
      <hr />
      <div className="table">
        <div className="--flex-between --flex-dir-column">
          <span>
            <h3>Inventory Items</h3>
          </span>
          <span>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </span>
        </div>

        {isLoading && <SpinnerImg />}

        <div className="table">
          {!isLoading && products.length === 0 && UserRole? (
            <p>-- No product found, please add a product...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>s/n</th>
                  <th>Name</th>
                  <th>Category</th>
                  {UserRole === "admin" && <th>Price</th>}
                  <th>SalePrice</th>
                  <th>Quantity</th>
                  <th>Sell</th>
                  <th>value </th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {currentItems.map((product, index) => {
                  const { _id, name, category, price,saleprice, quantity } = product;
                  const isSellDisabled = quantity <= 0;
                  const outOfStockClass = isSellDisabled ? "out-of-stock" : "";
                  return (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td>{shortenText(name, 32)}</td>
                      <td>{category}</td>
                      {UserRole==="admin" && <td>
                         {price}
                        {"₹"}
                      </td>}
                      <td>
                        {saleprice}
                        {"₹"}
                      </td>
                      
                      <td>{quantity}</td>
                      <td >
                        
                        <FaMinusCircle  className={outOfStockClass} onClick={()=>confirmSell(_id)} disabled={isSellDisabled}/>
                      </td>
                      <td>
                      {Math.round((saleprice - price) / price * 100 * 100) / 100}%
                
                      </td>
                     
                      <td className="icons">
                        <span>
                          <Link to={`/product-detail/${_id}`}>
                            <AiOutlineEye size={25} color={"purple"} />
                          </Link>
                        </span>
                        <span>
                          <Link to={`/edit-product/${_id}`}>
                            <FaEdit size={20} color={"green"} />
                          </Link>
                        </span>
                        <span>
                          <FaTrashAlt
                            size={20}
                            color={"red"}
                            onClick={() => confirmDelete(_id)}
                          />
                        </span>
                      </td>
                     
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="Prev"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="activePage"
        />
      </div>
    </div>
  );
};

export default ProductList;