import React from 'react';
import { useSelector } from 'react-redux';
import {selectgetOutOfStockProducts } from '../../redux/features/product/productSlice';
import "../../components/product/productList/productList.scss"

const OutOfStock = () => {
  const outOfStockProducts = useSelector(selectgetOutOfStockProducts);

  
  
  
  const totalProductsCount = outOfStockProducts; // Assuming total product count is available
  console.log(totalProductsCount,"inside ouutofstockjs");




  // Render out-of-stock products
  return (
    <div className="product-list">
      <h3>Out Of Stock Inventory</h3>
      <div  className="table">  
        
        
      {outOfStockProducts.length===0 ? (
        <p>---No Product yet..</p>
      ):(
        <table>
          <thead>
           <tr>
            <th>S/N</th>
            <th>Name</th>
            <th>Category</th>
            <th>Price</th>

           </tr>
          </thead>
          {outOfStockProducts.map((product,index)=>
          <tbody key={product._id}>
            <td>{index + 1}</td>
            <td>{product.name}</td>
            <td>{product.category}</td>
          </tbody>
          )}
        </table>
      )}
       </div>
    </div>
  );
};

export default OutOfStock;
