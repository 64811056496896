import React, { useEffect,useState } from "react";
// import Select from 'react-select'
// import { useSelect } from 'react-select';
import "react-quill/dist/quill.snow.css";
import Card from "../../card/Card";
import "./ProductForm.scss";


const SellProductForm = ({
  product,
  saveProduct,
  handleInputChange

}) => { 
  const data="";
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedProduct, setSelectedProduct] = useState(null);
  const [error, setError] = useState(null);


  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading state to indicate data fetching
      setError(null); // Clear any previous errors
    };
    fetchData();
  }, []);


  
  const handleProductChange = (event) => {
    // Extract selected product information based on your data structure
    // const selectedProductId = event.target.value;
    // You might need to find the corresponding product object from 'data'
    // const selectedProduct = data.find((item) => item.id === selectedProductId);

    // Handle product selection as needed (e.g., update state or pass to saveProduct)
  };
  
  return (
    <div className="add-product">
      <Card cardClass={"card"}>
        <form onSubmit={saveProduct}>
         
          <label>Product Name:</label>
          {/* <input
            type="text"
            placeholder="Product name"
            name="name"
            value={product?.name}
            onChange={handleInputChange}
          /> */}
          <select
            value="" // Set default value initially
            onChange={handleProductChange}
            disabled={isLoading} // Disable dropdown while loading
          >
            {isLoading ? (
              <option disabled>Loading products...</option>
            ) : error ? (
              <option disabled>Error: {error}</option>
            ) : (
              data.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))
            )}
          </select>

          {/* <label>Product Category:</label> */}
          {/* <input
            type="text"
            placeholder="Product Category"
            name="category"
            value={product?.category}
            onChange={handleInputChange}
          /> */}

          {/* <label>Product Price:</label>
          <input
            type="text"
            placeholder="Product Price"
            name="price"
            value={product?.price}
            onChange={handleInputChange}
          /> */}

          <label>Product Sale Price:</label>
          <input
            type="text"
            placeholder="Product Sale Price"
            name="saleprice"
            value={product?.saleprice}
            onChange={handleInputChange}
          />

          <label>Product Quantity:</label>
          <input
            type="text"
            placeholder="Product Quantity"
            name="quantity"
            value={product?.quantity}
            onChange={handleInputChange}
          />

          {/* <label>Product Description:</label>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            modules={ProductForm.modules}
            formats={ProductForm.formats}
          /> */}

          <div className="--my">
            <button type="submit" className="--btn --btn-primary">
              Save Product
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};



export default SellProductForm;