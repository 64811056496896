import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductList from "../../components/product/productList/ProductList";
import ProductSummary from "../../components/product/productSummary/ProductSummary";
import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";
import { getUserRole, selectIsLoggedIn } from "../../redux/features/auth/authSlice";
import { getOutOfStockProducts, getProducts } from "../../redux/features/product/productSlice";

const Dashboard = () => {
  useRedirectLoggedOutUser("/login");
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { products, isLoading, isError, message } = useSelector(
    (state) => state.product
  );

  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(getProducts());
      dispatch(getOutOfStockProducts())
      dispatch(getUserRole())
    }

    if (isError) {
      console.log(message);
    }
  }, [isLoggedIn, isError, message, dispatch]);

  // useEffect(() => {
  //   // Dispatch the thunk when the component mounts or on a specific condition
  //   dispatch(getUserRole())
  //     .then((data) => {
  //       console.log("Fetched user data:", data); // Log the fetched data
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user role:", error); // Log any errors
  //     });
  // }, []);

  return (
    <div>
      <ProductSummary products={products} />
      <ProductList products={products} isLoading={isLoading} />
    </div>
  );
};

export default Dashboard;