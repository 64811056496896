import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import SellProductForm from "../../components/product/sellProductform/sellproductForm";
import {
  getProducts,
  selectIsLoading,
} from "../../redux/features/product/productSlice";

const initialState = {
  name: "",
  quantity: "",
  saleprice:""
};

const SaleProduct = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState(initialState);


  const isLoading = useSelector(selectIsLoading);

  const { name,saleprice, quantity } = product;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  // const handleImageChange = (e) => {
  //   setProductImage(e.target.files[0]);
  //   setImagePreview(URL.createObjectURL(e.target.files[0]));
  // };

//  const getProducts = async () => {
//   const response = await axios.get(API_URL);
//   return response.data;
// };


  const saveProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("quantity", Number(quantity));
    formData.append("saleprice", saleprice);

    console.log(...formData);

    // await dispatch(saleOrder(formData));

    navigate("/dashboard");
  };

  return (
    <div>
      {isLoading && <Loader />}
      <h3 className="--mt">Sale Product</h3>
    <SellProductForm
    product={product}
    saveProduct={saveProduct}
    getProducts={getProducts}
    handleInputChange={handleInputChange}
    />
    </div>
  );
};

export default SaleProduct;