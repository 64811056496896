import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {getUser} from "../../../services/authService"
const name=JSON.parse(localStorage.getItem("name"));


const initialState = {
   isLoggedIn:false,
   name:name ? name : "",
   user: {
    name: "",
    email: "",
    phone: "",
    bio: "",
    photo: "",
    role:"",
  },
 userID:""
}

// Create an asynchronous thunk to fetch user data using your authService
export const getUserRole = createAsyncThunk(
  "auth/getUser",
  async () => {
    try {
      const response = await getUser(); // Call your authService function
    
      return response; // Replace with appropriate data extraction logic
    } catch (error) {
      return Promise.reject(error.message);
    }
  }
)

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_LOGIN(state,action){
      state.isLoggedIn = action.payload;
    },
    SET_NAME(state, action) {
      localStorage.setItem("name", JSON.stringify(action.payload));
      state.name = action.payload;
    },
    SET_USER(state, action) {
      const profile = action.payload;
      state.user.name = profile.name;
      state.user.email = profile.email;
      state.user.phone = profile.phone;
      state.user.bio = profile.bio;
      state.user.photo = profile.photo
    },
    SET_USER_ROLE(state,action){
      state.user.role=action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserRole.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUserRole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(getUserRole.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  }, 
});
 
export const {SET_LOGIN, SET_NAME, SET_USER,SET_USER_ROLE} = authSlice.actions

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectName = (state) => state.auth.name;
export const selectError = (state) => state.auth.error;
export const selectUser = (state) => state.auth.user;
export const selectUserRole = (state) => state.auth.user.role;
export const selectgetUserRole = (state) => state.auth.user.getUserRole;

export default authSlice.reducer